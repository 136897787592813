export const APP_ALERT = 'APP_ALERT';
export const APP_ALERT_CLEAR = 'APP_ALERT_CLEAR';

export const GET_SITES_REQUEST = 'GET_SITES_REQUEST';
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_SITES_FAILURE = 'GET_SITES_FAILURE';

export const GET_DATES_REQUEST = 'GET_DATES_REQUEST';
export const GET_DATES_SUCCESS = 'GET_DATES_SUCCESS';
export const GET_DATES_FAILURE = 'GET_DATES_FAILURE';

export const GET_TIMESLOTS_REQUEST = 'GET_TIMESLOTS_REQUEST';
export const GET_TIMESLOTS_SUCCESS = 'GET_TIMESLOTS_SUCCESS';
export const GET_TIMESLOTS_FAILURE = 'GET_TIMESLOTS_FAILURE';

export const SET_SITE = 'SET_SITE';
export const SET_DATE = 'SET_DATE';
export const SET_MONTH = 'SET_MONTH';
