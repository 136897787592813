import React from 'react';
import PropTypes from 'prop-types';
import './loading.css';

const Loading = props => (
  <div className="loading-plane">
    <svg className="svg-plane" width="34px" height="39px" viewBox="0 0 34 39" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-960.000000, -595.000000)" fill="#9DDAE9">
          <g transform="translate(670.000000, 592.000000)">
            <path
              d="M319.958868,33.5924238 C319.572354,31.7338967 311.954606,20.8996938 311.954606,20.8996938 C311.954606,20.8996938 318.424443,14.5972885 318.852833,13.8495209 C319.278108,13.1017532 320.916982,11.4256091 318.827605,9.69263924 C316.736671,7.96281131 315.239532,9.08125882 314.49954,9.74888255 C313.756432,10.4196481 308.199479,15.4386081 308.199479,15.4386081 C308.199479,15.4386081 296.917546,7.1554529 295.054191,7.00921471 C293.192394,6.86140563 291.949247,8.54072037 291.949247,8.54072037 L303.426172,20.209406 L297.802347,26.8999108 L292.777754,25.4212316 L290,27.1459946 C290,27.1459946 295.513867,29.5704543 296.604554,30.9671568 C297.696799,32.3638594 299.136708,37 299.136708,37 L301.783615,35.5941416 L300.547696,29.4906969 L306.931928,24.3665393 L317.877614,36.470355 C317.88073,36.4703552 320.345381,35.450951 319.958868,33.5924238 Z"
              transform="translate(305.000000, 22.000000) rotate(42.500000) translate(-305.000000, -22.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
    <span className="message">{props.text.toLowerCase()}</span>
  </div>
);

Loading.propTypes = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: 'Loading ...',
};

export default Loading;
